import audio1 from '../../../../assets/sounds/child/de/diktat/03.mp3';
import audio2 from '../../../../assets/sounds/child/de/diktat/04.mp3';
import audio3 from '../../../../assets/sounds/child/de/diktat/05.mp3';
import audio4 from '../../../../assets/sounds/child/de/diktat/06.mp3';
import audio5 from '../../../../assets/sounds/child/de/diktat/07.mp3';
import audio6 from '../../../../assets/sounds/child/de/diktat/08.mp3';
import audio7 from '../../../../assets/sounds/child/de/diktat/09.mp3';
import audio8 from '../../../../assets/sounds/child/de/diktat/10.mp3';
import audio9 from '../../../../assets/sounds/child/de/diktat/11.mp3';
import audio10 from '../../../../assets/sounds/child/de/diktat/12.mp3';
import audio11 from '../../../../assets/sounds/child/de/diktat/13.mp3';
import audio12 from '../../../../assets/sounds/child/de/diktat/14.mp3';
import audio13 from '../../../../assets/sounds/child/de/diktat/15.mp3';
import audio14 from '../../../../assets/sounds/child/de/diktat/16.mp3';
import audio15 from '../../../../assets/sounds/child/de/diktat/17.mp3';
import audio16 from '../../../../assets/sounds/child/de/diktat/18.mp3';
import audio17 from '../../../../assets/sounds/child/de/diktat/19.mp3';
import audio18 from '../../../../assets/sounds/child/de/diktat/20.mp3';
import audio19 from '../../../../assets/sounds/child/de/diktat/21.mp3';
import audio20 from '../../../../assets/sounds/child/de/diktat/22.mp3';


const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,

};

export default audioFiles;
