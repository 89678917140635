import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import Logo from '../../assets/logo-white-2.png';
import {begin} from '../../utils/beforeunload';
import Introduksjon from '../../components/Introduksjon';
import Orddiktat from '../../components/Orddiktat';
import Ordkjedetesten from '../../components/Ordkjedetesten';
import Skrivehastighet from '../../components/Skrivehastighet';
import Leseproven from '../../components/Leseproven';
import Ordforstaelse from '../../components/Ordforstaelse';
import Ferdig from '../../components/Ferdig';
import getTestInfo from '../../api/getTestInfo';
import getUserInfo from '../../api/getUserInfo';
import getUserGrade from '../../api/getUserGrade';
import setAnamneseVersion from '../../api/setAnamneseVersion';
import {setTestCandidateGrade} from '../../actions/index';
import Byttelyd from '../../components/Byttelyd';

const testSteps = [
	Introduksjon,
	Byttelyd,
	Ordkjedetesten,
	Skrivehastighet,
	Leseproven,
	Ordforstaelse,
	Orddiktat,
	Ferdig,
];
var renderRemainingTests = [];
const testIdsToName = [
	Introduksjon,
	Byttelyd,
	Ordkjedetesten,
	Skrivehastighet,
	Leseproven,
	Ordforstaelse,
	Orddiktat
];
class ScreeningTest extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoggedIn: false,
			includeAnamnese: false,
		};
	}

	componentDidMount() {
	
		getTestInfo().then((results) => {
			const exclusiveTests = results.exclusiveTests;
			const grade = Number(results.grade);
			this.props.onSetTestCandidateGrade(grade);
			const testToContinue = Object.values(exclusiveTests);
			if (testToContinue.length) {
				for (let i = 0; i < testToContinue.length + 1; i++) {
					// eslint-disable-next-line default-case
					switch (testToContinue[i]) {

						case 3:
							renderRemainingTests[0] = testIdsToName[1];
						break;
						case 2:
							renderRemainingTests[1] = testIdsToName[2];
					break;
				case 4:
					renderRemainingTests[2] = testIdsToName[3];
					break;
				case 6:
					renderRemainingTests[3] = testIdsToName[4];
					break;
				case 5:
					renderRemainingTests[4] = testIdsToName[4];
					break;
				case 1:
					renderRemainingTests[5] = testIdsToName[5];
					break;
					
					}
					if (i === testToContinue.length) {
						renderRemainingTests = renderRemainingTests.filter(function (element) {
							return element !== undefined;
						});
						renderRemainingTests.push(Ferdig);
					}
				}
				getUserGrade().then((results) => {
					this.props.onSetTestCandidateGrade(results.grade);
				});
			} else if (grade) {
				renderRemainingTests.push(	
				Byttelyd,
				Ordkjedetesten,
				Skrivehastighet,
				Leseproven,
				Ordforstaelse,
				Orddiktat,
				Ferdig,
				);
				this.props.onSetTestCandidateGrade(grade);
			}

			setAnamneseVersion().then((response) => {
				if (response === 'True') {
					this.setState({includeAnamnese: true});
				} else {
					this.setState({includeAnamnese: false});
				}
			});

			getUserInfo().then((response) => {
				if (response.ok) {
					this.setState({
						isLoggedIn: true,
					});
					begin();
				} else {
					window.location.href = `/`;
				}
			});
		});
	}

	render() {
		const {isLoggedIn} = this.state;

		if (!isLoggedIn) {
			return null;
		}

		const {currentStepIndex, onSetTestCandidateGrade} = this.props;

		if (renderRemainingTests.length) {
			var CurrentStep = renderRemainingTests[currentStepIndex];
		} else {
			var CurrentStep = testSteps[currentStepIndex];
		}

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate-logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					<CurrentStep
						includeLongAnamnese={this.state.includeAnamnese}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentStepIndex} = state;

	return {
		currentStepIndex,
	};
};
const mapDispatchToProps = {
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningTest);
