import React from 'react';

export const TextGrade2 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`TITEL DES TEXTES`}</h2>
				<p>
					{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id arcu vitae purus rhoncus tempus. Nam laoreet in neque eu congue. Sed placerat, mi nec varius accumsan.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Hier ist eine Frage für den Text',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Hier ist eine Alternative a',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Hier ist eine Alternative b',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Hier ist eine Alternative c',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Hier ist eine Alternative d',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				{<h2 className="text-center">{`2 von 8`}</h2>}
				<p>
				{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id arcu vitae purus rhoncus tempus. Nam laoreet in neque eu congue. Sed placerat, mi nec varius accumsan.elit lorem euismod tellus, nec sagittis felis enim et purus. Aenean ac erat vel mauris euismod rhoncus at et est. Integer rhoncus dapibus massa at faucibus.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hier ist eine Frage für den Text',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Hier ist eine Alternative a',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Hier ist eine Alternative b',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Hier ist eine Alternative c',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'Hier ist eine Alternative d',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				{<h2 className="text-center">{`3 av 8`}</h2>}
				<p>
					{`
					Pål kan bli ven med Noah. Noah vil ha nye vener. Nokon samlar på ting. Noah samlar på vener.
			
					Pål har mange spel. Han har også puslespel, brio og dam. `}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Korleis veit vi at Noah likar å ha mange vener?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han likar nyhende',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han samlar på ting',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'han likar å spele spel med andre',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'han samlar på vener',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				{<h2 className="text-center">{`4 av 8`}</h2>}
				<p>
					{`Noah likar brio betre enn dam. Dam er ikkje så gøy. Dam er det berre vaksne som spelar.
			Noah kjenner ingen born som spelar dam. Det er betre å leike med brio.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Kven er det Noah meiner det er som spelar dam oftast?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'born',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'vaksne',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'dyr i dammar',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'damer',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>
					{`Pål er heime. Noah spør om han får kome på besøk. Pål blir glad.	`}
				</p>
				<p>{`- Kom inn, seier Pål. - Eg har fått eit nytt spel.`}</p>
				<p>{`- Har du fått eit nytt spel? spør Noah.`}</p>
				<p>{`- Eg har fått eit nytt dataspel.`}</p>
				<p>{`- Så gøy! Er det bra?`}</p>
				<p>{` - Ja, seier Pål. - Det er kjempekult!`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Kvifor blir Pål glad?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han kjedar seg',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han får eit nytt spel',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'han skal bade',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'fordi han får besøk',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>
					{`Noah blir med Pål til rommet. Det er så mange spel der, at han ikkje veit kvar han skal sitte.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Kvar går dei for å spele?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'i kjellaren',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'i stova',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'på rommet',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'på kjøkenet',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`Pål set seg ned og spelar. 
			Noah set seg ned han òg.
			Fyrst set Noah seg på lego, men flyttar på seg. Pål spelar godt. Han er veldig flink til å spele bilspel. Noah er flink i sport.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Kvifor flyttar Noah på seg?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'fordi sofaen er vond',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi han set seg på Lego',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'han ser ikkje dataskjermen',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han har slege seg på foten',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`Når dei er leie av å spele, må Noah heim igjen. `}</p>
				<p>{`- Takk for i dag, seier han.`}</p>
				<p>{`- Skal vi møtast igjen i morgon? spør Pål.`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Korleis veit Noah at han har har fått ein ny ven?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Pål spør om dei er vener',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Pål er flink til å spele dataspill',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'Pål spør om dei skal møtast igjen i morgon',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Pål gir Noah ein klem',
					},
				],
			},
		],
	},
];

export const TextGrade3 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`MIRA ER BORTE!`}</h2>
				<p>{`- Mira er borte!`}</p>
				<p>{`- Kva er det du sier, Lise? Mamma ser trist bort på henne. `}</p>
				<p>{`- Når såg du henne sist? `}</p>
				<p>{`- For berre fem minutt sidan, seier Lise.`}</p>
				<p>{` - Nokon har late døra stå open igjen!`}</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Når såg Lise hunden Mira sist?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'for fem timar sidan',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'for fem minutt sidan',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'for fem dagar sidan',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'for femten minutt sidan',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Lise kikkar bort på pappa. Det var han som lét døra stå open sist Mira blei borte.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Kvifor trur du Lise kikka bort på faren?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'faren veit kvar Mira er',
					},
					{
						letter: 'b',
						identity: 'd',
						choice:
							'ho mistenker faren for å ha lete døra stå open',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'faren veit meir enn han seier',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'faren har gløymt Mira ute',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`- Huff, seier pappa.`}</p>
				<p>{`- Det var nok meg igjen. Eg var berre ute og henta posten.`}</p>
				<p>{`- Du må då lære deg å lukke døra etter deg. Du er då vaksen! seier Lise surt. `}</p>
				<p>{`- Unnskyld, seier pappa og blir raud i andletet.`}</p>
				<p>{`Lise tek på seg jakka og går ut i kulden. Det er ti minus og snø ute.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text:
					'Kvifor meiner Lise at faren burde klare å lukke døra etter seg?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'faren veit meir enn han seier',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'fordi han er vaksen',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'faren har gløymt Mira ute',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'faren veit kvar Mira er',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>{`- Mira! ropar Lise, men ingen svarer.`}</p>
				<p>{`- Mira! ropar mamma og pappa.`}</p>
				<p>{`Lise ser etter spor i snøen. Det er nysnø ute, og ingen spor etter Mira.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text:
					'Kva kan grunnen vere til at det ikkje er spor etter Mira ute?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Mira har gått ein annan sted',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Mira har blitt stolen av nokon',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mira har stukke av',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'det er nysnø ute',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`- Vi deler oss opp, seier mamma og pappa.`}</p>
				<p>{`- Lise, du kan gå rundt i hagen og sjekke om 
					Mira er der, så går vi ein runde i nabolaget. `}</p>
				<p>{`- OK! seier Lise, og går inn i garasjen og sjekkar.`}</p>
				<p>{`Tomt. Ikke noko spor etter Mira. Lise sjekkar i hundehuset òg. 
					Tomt der òg. Ho går ein runde rundt huset, men Mira er ikkje å sjå.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Kvar skal foreldra til Lise leite etter Mira?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'dei leitar i garasjen',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'dei går ein runde i nabolaget',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'dei leitar i hagen',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'dei varslar politiet',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>
					{`Det er då Lise kjem på det. Ho spring inn i huset igjen og opp trappene. 
					Mira må ha gøymt seg på same stad som sist, tenker Lise. Ho har heilt sikkert ikkje rømt, sjølv om pappa hadde gløymt 
					å lukke døra til huset igjen.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Kva er det Lise kjem på?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Mira må ha gøymt seg same stad som sist',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Mira har rømt',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mira søv i kjellaren',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Mira er hjå dyrlegen',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`- Mira! ropar Lise, men ingen svarar.`}</p>
				<p>{`Ho opnar døra til badet, og der ligg Mira. Ho har sove godt, og lograr når Lise kjem inn.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Kva ser Lise når hun åpner badedøra?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'rommet er tomt',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'foreldra',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mira som har vakna',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Mira som bjeffar',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>
					{`- Sjølvsagt er du her og varmar deg på badegolvet, seier Lise og stryk henne over magen. `}
				</p>
				<p>{`- Dette er jo typisk deg!`}</p>
				<p>{` 
					Mira lograr og er glad kvar gong Lise helsar på henne.`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Kvifor er det typisk Mira å ligge på badet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Mira likar å sove',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Mira likar å logre',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Mira likar Lise',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Mira likar varmen i golvet',
					},
				],
			},
		],
	},
];
export const TextGrade4 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`BUE, PILER OG SVARTE DUER`}</h2>
				<p>
					{`
			Ailo gler seg til skulen er ferdig.
			Mamma skal hjelpe han å lage pil og boge! 
			Visste du at indianarane brukte pil og boge? Dei var flinke jegerar. 
			Indianarane brukte også spyd, men Ailo likar ikkje spyd. 
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Kvifor trong indianarane pil og boge?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'dei måtte beskytte seg',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi dei ikkje likte å bruke spyd',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'dei var flinke jegerar',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'fordi mamma hadde sagt de',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>{`- Kvar er mamma? sa Ailo til pappa då han kom heim.`}</p>
				<p>{`- Mamma må jobbe overtid, sa pappa.`}</p>
				<p>{`- Og vi som skulle lage pil og boge i dag! sa Ailo.`}</p>
			</span>
		),

		questions: [
			{
				id: 2,
				text: 'Kvifor blei Ailo skuffa?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'pappa må jobbe overtid',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'faren vil ikkje lage pil og boge',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'mamma må jobbe overtid',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'skulen er ikkje ferdig',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`- Det var i dag det ja, sa pappa. Han rørte i gryta og smakte på maten.`}</p>
				<p>{`- Ja, sa Ailo skuffa. `}</p>
				<p>{`- Eg veit at du har gledd deg.`}</p>
				<p>{`- Ja, eg har gledd meg i heile dag. `}</p>
				<p>{`- Eg likar pil og boge eg òg, sa pappa. `}</p>
				<p>{`- Ikkje like mykje som mamma. `}</p>
				<p>{`- Nei, kanskje ikkje like mykje som mamma. Pappa sa det berre for å trøyste han.`}</p>
			</span>
		),

		questions: [
			{
				id: 3,
				text:
					'Kvifor meinte Ailo at faren sa at han likte pil og boge han òg?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'for å trøyste han',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'faren likar indianarar',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'faren likar mora',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'faren likar våpen',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`Dei åt middag, og pappa babla om kor god lapskausen var. 
					Den var heilt grei, men ikkje kjempegod. Etter maten kom mamma heim frå jobb. 
					Ho såg sliten ut.`}
				</p>
			</span>
		),

		questions: [
			{
				id: 4,
				text: 'Kva babla faren om?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'at han elskar varm mat',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'oppskrifta til lapskausen',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'kva som hadde skjedd på jobb',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'kor god middagen var',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`- Mamma! ropte Ailo, og gav henne ein klem.`}</p>
				<p>{`- Hei, gullet mitt, sa mamma og kyste han. `}</p>
				<p>{`- Skal vi lage pil og boge etter at eg har ete? `}</p>
				<p>{`- Er du sikker på at du ikkje er for sliten? sa Ailo. `}</p>
				<p>{`- Eg er kjempesliten, men eg har lovt å hjelpe deg.`}</p>
				<p>{`Mamma var den beste. Ho ville hjelpe Ailo sjølv om ho var så sliten.`}</p>
			</span>
		),

		questions: [
			{
				id: 5,
				text: 'Kvifor var mamma den beste??',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice:
							'fordi ho ville hjelpe Ailo sjølv om ho var så sliten',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi ho ville lage pil og boge',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'fordi hun kalla han ”gullet sitt”',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'fordi ho klemte han',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Då er eg klar, sa mamma.`}</p>
				<p>{`- Jippi! sa Ailo, og sprang etter henne til bua. `}</p>
				<p>{`Mamma var flink til å spikke. Ho spikka på ein lang pinne som skulle bli bogen. 
					Fleire korte pinnar skulle bli pilene. Ailo pussa bogen og pilene med pussepapir, 
					og mamma måla pilene med fine fargar.`}</p>
				<p>{`- Då må alt få tørke til i morgon, så kan vi skyte på svarte leirduer, sa mamma.`}</p>
			</span>
		),

		questions: [
			{
				id: 6,
				text: 'Kor lenge måtte målinga tørke?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'til måndag',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'i ein time',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'til i morgon',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'i 24 timar',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`Plutseleg kom pappa inn døra med eit brak.`}</p>
				<p>{`- Korleis går det med dykk? sa pappa, og snubla. `}</p>
				<p>{`Han trakka på bogen og pilene så dei knakk. 
					Han fall på dei svarte leirduene. Han knuste alt saman!`}</p>
			</span>
		),

		questions: [
			{
				id: 7,
				text: 'Kva trakka faren på?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'leirduene',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'pilene og bogen',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'foten til Ailo',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'målinga',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`- Oi, sann! sa pappa.`}</p>
				<p>{`- Ja, det kan du seie, sa mamma. Ho gnidde seg på hovudet.`}</p>
				<p>{`- Vi får starte på nytt igjen i morgon. `}</p>
				<p>{`- Ja, men då skal pappa gjere alt arbeidet, sa Ailo.`}</p>
			</span>
		),

		questions: [
			{
				id: 8,
				text: 'Kvifor meinte Ailo at faren skulle gjere alt arbeidet?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'fordi faren kom inn døra',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi faren snubla og fall',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'fordi faren var ein dust',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'fordi det var faren som hadde øydelagt alt',
					},
				],
			},
		],
	},
];

export const TextGrade5 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`GULLGRAVEREN`}</h2>
				<p>
					{`Ola skal bli gullgravar når han blir stor. Han øver på å grave kvar einaste dag. 
					Ein gong fann mora ein gullring på eit jorde. Han var verd minst tusen kroner. 
					Det var ein ring frå vikingtida. Ho måtte levere han inn til ein arkeolog. 
					Mora fekk tre hundre kroner i finnarløn.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Kva var det mora fann ein gong?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'tusen kroner',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'ein gullring',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'tre hundre kroner',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'finnarlønn',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Visste du at det finst gull i Noreg? Ein gong var det nokon som fann ein klump som var verd ti tusen kroner. 
					I dag er heile familien på jakt etter gull i Finnmark. Dei har fått lov til å vaske etter gull i elva Nytysjokka. 
					Elva ligg i nærleiken av Karasjok.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Kva er det Ola og familien skal gjere i dag?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'besøke Karasjok',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'bade i Nytysjokka',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'gå på jakt',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'vaske etter gull',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Mora og faren har starta å vaske sand i elva med ei stor panne. 
					Panna er svart, og det gjer det lettare å sjå gull som glitrar.`}
				</p>
				<p>{`Ola har vaska gull i tre timar no. Han har funne fem små korn, men han jaktar på gullklumpar. 
					Ola vaskar og vaskar, men finn ikkje meir. Mora og faren finn ikkje noko dei heller.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Kva er det Ola jaktar på?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'gullkorn',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'gullklumpar',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'ei stor panne',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'elg',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`Det begynner å bli mørkt, og foreldra pakkar saman tinga dei har. Dei får ikkje lov til å leite etter midnatt.`}
				</p>
				<p>{`- Vi dreg no! ropar faren. `}</p>
				<p>{`- Eg kjem! ropar Ola. Klokka er kvart på tolv. Ola har framleis ein sjanse til å finne ein gullklump.`}</p>
				<p>{`- Vi ventar i bilen, seier mora.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Kvifor må familien pakke sammen og dra?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'det begynner å bli mørkt',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'dei får ikkje leite etter midnatt',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'dei er trøytte',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'flyet går snart',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 3`}</h2>
				<p>
					{`
			Ola tuslar ned til elva, og går til staden der elva svingar brått. Han har lese at gull er tyngre enn stein. 
			Der vil gullet miste fart og søkke til botnen. 
			`}
				</p>
				<p>
					{`
		
			Panna er full. Ola plukkar ut dei største steinane og vaskar vekk det øvste laget med sand. 
			Det er ikkje noko som glitrar, så han vaskar vidare.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Kvifor leitar Ola der elva svingar brått?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'straumen er ikkje så sterk',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'vatnet er varmare der',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'fordi gull er tyngre enn stein',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'gullet søkk til botnen der',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>
					{`- No må du kome! ropar faren. Mora står ved bilen med ei lommelykt. 
					
					`}
				</p>
				<p>{`- Eg kjem! ropar Ola. `}</p>
				<p>
					{`Idet Ola skal til å gå, ser han det glitre på botnen av elva. Vatnet er grumsete, så han må vere rask. 
					Han bøyer seg ned og grip etter det som glitrar. Handa er fylt med sand og småsteinar.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Kva ser Ola i vatnet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'noko som glitrar',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'grumsete vatn',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'sand',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'småstein',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`
			Ola vaskar forsiktig bort sanden, og det er no han ser gullklumpen. 
			Han er fire centimeter lang og mykje tyngre enn steinen som er litt større.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Kor lang er gullklumpen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: '4 liter',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: '4 decimeter',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: '4 centimeter',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: '4 millimeter',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`- Herregud, eg har funne gull!`}</p>
				<p>{`- Klokka er over midnatt, ropar faren. - Vi har ikkje lov til å leite etter tolv!`}</p>
				<p>{`- Eg kjem, seier Ola. `}</p>
				<p>{`- Fann du noko? spør mora.`}</p>
				<p>{`- Ja, sjekk den gullklumpen!`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Kven er det som lurar på om Ola fann noko?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'faren',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'mora',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'begge foreldra',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'bestemora',
					},
				],
			},
		],
	},
];
export const TextGrade6 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`SPRELSKE SPØKELSER`}</h2>
				<p>
					{`Annie likar sprelske spøkelse. Ho har sett minst tusen stykke! 
					På loftet er det kjempemange sprelske spøkelse kvar einaste kveld, og dei kviskrar, hyler, ular og jamrar. 
					Dei bråkar så fælt at faren ikkje får sove. 
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Kvifor får ikke faren sove?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Annie held han vaken',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'spøkelsa bråkar så fælt',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'ungar ringer på og stikk av',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han er ikkje trøytt',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Annie kan sjå spøkelse. Det har ho gjort sidan ho var fem år. No er ho elleve år, og stor. 
					Annie synest det er kjempekult at dei lagar så mykje rabalder. Det er som regel ho som får skulda for alt bråket, 
					men faren har trudd på henne i det siste. No skuldar han på spøkelsa. 
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Kven skulda faren på for alt bråket før?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'spøkelsa',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Annie',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'systera',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'naboen',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Det er halloween i dag, og Annie har brukt kvelden på å gå på dørene. 
					Ho har fått fem kilo med smågodt, og merkelege kaker som naboen Lotte har bakt. 
					I år hadde ho bakt heksefingrar med negler av mandel.
					`}
				</p>
				<p>{` Annie er skikkeleg sliten, så ho legg seg under dyna. 
					 Det er no moroa startar. Fem sprelske spøkelse dukkar opp frå under senga. 
					 Dei jålar og lagar grimasar for å prøve å skremme henne, men ho er vand til bråket.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Kva slags moro er det som startar no?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Annie får smågodt av naboane',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'spøkelsa jagar Annie',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'spøkelsa lagar grimasar til faren',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'spøkelsa dukkar opp',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`Annie snur seg rundt og latar som om ho har sovna. Spøkelsa skjønar at dei ikkje får merksemd. 
					Dei trur ho søv, og akkurat då snur ho seg brått. 
					`}
				</p>
				<p>{`- Bø! hyler Annie.`}</p>
				<p>{`Dei sprelske spøkelsa skvett til, og flyg rundt i rommet som vettskremde småfuglar.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Korleis får Annie spøkelsa til å forsvinne?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'ho latar som ho søv',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'ho skremmer dei',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'ho har vindauga opent',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'ho ringer ein prest',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>
					{`Akkurat idet det siste forsvinn inn under senga, kjem faren inn på rommet. 
					`}
				</p>
				<p>{`- Kva er det som skjer? seier faren. 
					`}</p>
				<p>{`- Ingenting, seier Annie, og latar som om ho nettopp blei vekt. Ho gjespar høgt. 
					`}</p>
				<p>{`- Kva var det eg høyrde, då? seier faren. 
					`}</p>
				<p>{`- Det var sikkert dei sprelske spøkelsa igjen, seier ho, og snur seg mot veggen.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text:
					'Kvifor trur du at Annie latar som ho nettop har blitt vekt når faren kjem inn på rommet?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'ho latar ikkje som',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'ho vil ikkje få skulda for bråket',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'ho vil ha ro og fred',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'ho vil ikkje skremme faren',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Er du sikker på at det var spøkelse?`}</p>
				<p>{`- Eg søv, pappa! seier Annie, og trekker dyna over hovudet.`}</p>
				<p>{`- Ja, vel. God natt, seier han, og lukkar døra etter seg.`}</p>
				<p>{`- God natt! mumlar Annie, og lukkar auga`}</p>
				<p>{`Ho må sove no. Ho er stuptrøytt, og ingen sprelske spøkelse skal få vekt henne.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Kven er det som ikkje skal få vekke Annie?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'faren',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'systera',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'spøkelsa',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'bestefar',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`Akkurat idet Annie held på å sovne, høyrer ho eit rabalder inne på soverommet til faren. 
					Ut frå lyden er det akkurat det same som skjedde i går. Det rare er at han ikkje lærer. 
					Ein skal ikkje gje spøkelse merksemd. Det er jo då dei synest det 
					er morosamt å tulle med oss.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Kva høyrer Annie frå soverommet til faren?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'bråk',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'snorkelydar',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'kjefting',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'musikk',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`- Hjelp! hyler faren og slenger ting i veggane.`}</p>
				<p>{`Spøkelsa torer ikkje kome inn til Annie i natt, men dei bråkar gjerne med han, for han blir så sint og redd.`}</p>
				<p>{`- God natt, pappa! ropar Annie. - Sov godt, sjølv om du aldri lærer! `}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Kva er det faren aldri lærer?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'matematikk',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'å skremme spøkelse',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'han må ikkje gi spøkelsa merksemd',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'ikkje bli redd',
					},
				],
			},
		],
	},
];
export const TextGrade7 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`FLAKS ELLER UFLAKS?`}</h2>
				<p>
					{`Morten harka og hosta opp sjøvatn på ei aude øy ein stad i Stillehavet, og lurte på om han var død. 
					Det einaste han hugsa, var at kapteinen på turistskipet hadde bråsnudd båten idet han hadde lent seg over båtrekka.`}
				</p>
				<p>
					{`Øya var kjempestor, men Morten hadde ikkje energi til å utforske henne. 
					Han hadde ikkje ete sidan i går.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Korleis hamna Morten i sjøen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han stupte',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han fall i sjøen på ei aude øy',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'han fall over rekka på båten',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han var på svømmetur',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`Eit passasjerfly glei forbi over skyene. Morten skreiv ”HJELP!” i store bokstavar i sanden, så flya kanskje kunne sjå det. 
					Han brukte ventetida til å kakke hól i kokosnøtter, så han fekk i seg næring. 
					Det var berre superflaks som gjorde at han hadde stranda på ei aude øy med kokospalmar.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Kvifor var det flaks at det var kokospalmar der?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han kunne klatre opp og sjå utover havet',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'han kunne speide etter fly',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'han fann næring',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'fordi det er rovdyr der om natta',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Dagane gjekk, og flya passerte som dei alltid gjorde, og Morten blei berre tynnare og tynnare. 
					Etter sekstifem dagar hadde han framleis berre kokosmjølk å drikke, og fisk klarte han ikkje å fange.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Kvifor blei Morten tynnare og tynnare?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'han åt feil mat',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'han fann berre drikke',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'han trente mykje',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'han ville ikkje ete',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8?`}</h2>
				<p>
					{`Det var flaks at Morten stranda, men uflaks at øya var aude og forlaten. 
					Han måtte gjere meir for å prøve å skaffe hjelp, så han fann ei tomflaske som låg blant drivved og rask. 
					Han skreiv på ein tøybit. Tøybiten stappa han inn i flaska og sette på korken. Det var verdt eit forsøk. 
					Morten kasta flaska så langt han kunne ut i sjøen, så måsar og andre sjøfuglar flaksa i alle retningar. 
					No måtte han berre vente. 
			`}
				</p>
				<p>
					{`Fleire dagar gjekk utan at noko nytt skjedde. Flya flaug forbi som dei alltid hadde gjort. 
					Fuglane flaug og flaksa rundt som om ingenting hadde skjedd.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Kva gjorde Morten med flaska?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'fylte henne med vatn',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'han knuste henne',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'han drakk av denne',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'brukte henne som flaskepost',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>
					{`Neste morgon, åttini dagar etter at hadde stranda på den aude øya, høyrde Morten ein motordur i det fjerne. Ein liten båt med påhengsmotor harka og hosta ut svart røyk ute på bølgene.
			`}
				</p>
				<p>
					{`- Hjelp! ropte Morten, og sprang til strandkanten. Mannen i farkosten oppdaga han og vinka tilbake. 
					Så styrte han motorbåten mot sandstranda.
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Kvar kom motorduren frå?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'frå skogen',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'lufta',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'frå sjøen',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'fra den fjerne austen',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Hei! ropte mannen.`}</p>
				<p>
					{`
					- Hei! ropte Morten tilbake.
					`}
				</p>
				<p>
					{`- Kva er det du ropar om hjelp for, unge mann? sa mannen i motorbåten.
			
			`}
				</p>

				<p>
					{`
					- Herregud, eg er så glad for å sjå deg, sa Morten
					og kasta seg rundt halsen hans, og gav han ein lang klem.
				
					
					`}
				</p>
				<p>{`	- Ta det med ro. Er alt bra?`}</p>
				<p>
					{`
					
					- Nei, eg har jo stranda på denne aude øya, sa Morten fortvila.
					`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Kva er det mannen i båten lurar på?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'kvifor Morten ropar om hjelp',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'om Morten er svolten',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'om Morten er tørst',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'om Morten likar seg på ei aude øy',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`- Aude øy? Dette er absolutt inga folketom øy, fortsette mannen. - Det er jo eit gigantisk stort hotell like bak skogholtet der.`}</p>
				<p>{`- Kva er det du seier? sa Morten overraska.`}</p>
				<p>{`- Stakkars. Du trudde du var på ei aude øy, du! Det var uflaks, men flaks at det er eit turisthotell her då, 
					så du kan få slappa av litt.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Kva er like bak skogholtet?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'hav',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'eit turisthotell',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'eit fjell',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'eit gigantisk stort troll',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>
					{`Morten nikka kort, men tenkte mest på kor utruleg dum han hadde vore.
			
			`}
				</p>
				<p>
					{`
		- Du ser tørst ut. Vil du ha eit par iskalde flasker med forfriskande brus?
			
			`}
				</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Kvifor meinte Morten at han hadde vore dum?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'fordi han hadde stranda på ei aude øy',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'fordi han ikkje hadde ringt familien',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'fordi han ikkje hadde undersøkt øya',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'fordi han kunne teke båten',
					},
				],
			},
		],
	},
];


