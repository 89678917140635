import {parsebool} from '../utils/typeconversion';

export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const TESTING = 'testing';

//Load environment from env vars. If not exists, default to youth
const validEnvironments = [DEVELOPMENT, TESTING, PRODUCTION];
const environmentFromEnvVars =
	process.env.REACT_APP_ENVIRONMENT?.toLowerCase() ||
	process.env.NODE_ENV ||
	'production';

export const ENVIRONMENT = validEnvironments.includes(environmentFromEnvVars)
	? environmentFromEnvVars
	: PRODUCTION; //Default to production, in case config is missing

export const IS_IN_PRODUCTION = ENVIRONMENT !== DEVELOPMENT;

//Load variation from env vars. If not exists, default to youth
export const AVAILABLE_VARIATIONS = ['youth', 'adult','child'];
const variantFromEnvVars =
	process.env.REACT_APP_VARIANT?.toLowerCase() || 'youth';
export const VARIATION = AVAILABLE_VARIATIONS.includes(variantFromEnvVars)
	? variantFromEnvVars
	: 'youth';

//Load is_norming_version from envvars. Leaving it blank or writing false, undefined, or null will be false.
export const IS_NORMING_VERSION =
	parsebool(process.env.REACT_APP_IS_NORMING, true, true) ?? false;

export const LANGUAGE = process.env.REACT_APP_LANGUAGE ?? 'en';

//Sanity check that the env and variation is set up correctly
if (!validEnvironments.includes(ENVIRONMENT)) {
	console.error('MISSING ENVIRONMENT SELECTOR');
}
if (!AVAILABLE_VARIATIONS.includes(VARIATION)) {
	console.error('MISSING VARIANT SELECTOR');
}

const endpointUrls = {
	development: 'http://localhost:3000',
	testing:
		LANGUAGE === 'no'
			? 'http://lit-testing.eu-central-1.elasticbeanstalk.com'
			: 'https://api.staging.dysmate.de',
	production:
		LANGUAGE === 'no'
			? 'https://screeningtest.literate.no'
			: 'https://api.dysmate.de',
};

export const ENDPOINT_URL = endpointUrls[ENVIRONMENT];

export const BANK_ID = 'BankID';
export const BANK_ID_MOBILE = 'BankIDMobile';
export const FEIDE = 'feide';
export const PINCODE = 'pincode';
export const NO_AUTH = 'NO_AUTH';

const ORDDIKTAT = 'ORDDIKTAT';
const ORDKJEDETESTEN = 'ORDKJEDETESTEN';
const TULLEORDTESTEN = 'TULLEORDTESTEN';
const BYTTELYD = 'BYTTELYD';
const SKRIVEHASTIGHET = 'SKRIVEHASTIGHET';
const LESEPRØVEN = 'LESEPRØVEN';
const ORDFORSTÅELSE = 'ORDFORSTÅELSE';

export const TEST_STEPS = {
	ORDDIKTAT,
	ORDKJEDETESTEN,
	BYTTELYD,
	SKRIVEHASTIGHET,
	LESEPRØVEN,
	ORDFORSTÅELSE,
};

export const TEST_STEP_IDS = {
	[ORDDIKTAT]: 1,
	[ORDKJEDETESTEN]: 2,
	[BYTTELYD]: 3,
	[SKRIVEHASTIGHET]: 4,
	[LESEPRØVEN]: 5,
	[ORDFORSTÅELSE]: 6,
};

export const DASHARRAY_LENGTH = 230;

export const UNAUTHORIZED = 401;
export const WRONGKEY = 422;

//To make testing faster you can reduce all test timers to 10 sec with the following:
//The production check is to make sure we never get reduced timers in production
const reduceTimers = false;
export const REDUCED_TIMERS = ENVIRONMENT !== PRODUCTION && reduceTimers;
