import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';
import previewVideoNo from './assets/no_skrivhastighet_video.mov';
import previewVideoNn from './assets/nn_skrivhastighet_video.mov';

import previewVideoSe from './assets/se_skrivhastighet_video.mov';
import previewVideoDe from './assets/de_skrivhastighet_video.mp4';

import {VARIATION} from '../../constants';

class Intro extends React.Component {
	constructor(props) {
		super(props);
		const variation = VARIATION;
		const language = strings.getLanguage();
		const grade = Number(this.props.candidateGrade);
		this.data = require(`../../assets/testdata/${variation}/${language}/skrivehastighet-data.json`);
		this.taskIsOver =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/SCR062.mp3`)
				: null;
		this.text = this.data[grade].value;
		this.state = {
			removeTestSentence: false,
		};


		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};
		const assets = {
			data: require(`../../assets/testdata/${variation}/${language}/skrivehastighet-data.json`),
			audio: require(`../../assets/sounds/${variation}/${language}/4 1 Skrivehastighet Intro.mp3`),
			video: previewVideoObj[language], //TODO: Use different ones for all langs
		};
		this.currentLang = language;
		this.audio = assets.audio;
		this.videoRefView = React.createRef();
		this.video = assets.video;
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div className="">
				<p className="text-left">
					<AudioPlayer
						autoplay
						inline
						onStart={() => {
							const scrollActionTime = 31000;

							if (scrollActionTime) {
								setTimeout(() => {
									if (this.videoRefView.current) {
										this.videoRefView.current.scrollIntoView(
											{
												behavior: 'smooth',
												block: 'start',
											}
										);
										videoRef.play();
									}
								}, scrollActionTime);
							}
						}}
						onPause={() => {
							if (!this.state.removeTestSentence) {
							videoRef.play();
							}
						}}
						onComplete={() => {
							if (!this.state.removeTestSentence) {
								this.videoRefView.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
								});
	
								videoRef.play();
								setTimeout(() => {
									this.setState({
										removeTestSentence: true,
									});
								}, 10000);
							}
						}}
						src={this.audio}
					/>
					{strings.skrivehastighetIntro}
				</p>
				<br/>
				<p className="text-left">
				{strings.skrivehastighetIntro1}
				</p>
				<br/>
				<p className="text-left">
				{strings.skrivehastighetIntro2}
				</p>
				<br/>
				<p className="text-left">
				{strings.formatString(strings.skrivehastighetIntro3,this.text)}
				</p>
				

				{!this.state.removeTestSentence && (
					<div className="lit-preview" ref={this.videoRefView}>
						<p className="lit-preview__label">
							{strings.taskLooksLikeThis}
						</p>
						<video
							className="lit-preview__video"
							loop
							onClick={() => {
								videoRef.play();
							}}
							ref={(ref) => {
								videoRef = ref;
							}}
							src={this.video}
						/>
					</div>
				)}

				<p>{strings.skrivehastighetHint}</p>
				<br/>
				<p>{strings.skrivehastighetIntroFinal}</p>

				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {candidateGrade} = state;
	return {
		candidateGrade,
	};
};

export default connect(mapStateToProps)(Intro);
