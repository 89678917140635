import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';
import {updateObject} from '../../utils/reducerUtils';
import AudioPlayer from '../../components/AudioPlayer';
import finalFinishSound from '../../assets/sounds/child/de/bytte-lyd-test-finished.mp3';

import Countdown from '../../components/Countdown';

import noData from './languages/no-data.json';
import nnData from './languages/nn-data.json';

import * as noTestData from './languages/no-test-data';

import allAnswers from './languages/noAnswers.json';

const tutorialObj = {
	no: noData,
	nn: nnData,
	de: noData,
};

const testObj = {
	no: noTestData,
	nn: noTestData,
	de: noTestData,

};
let count = 1;
let timeFlag = false;
class Test extends React.Component {
	constructor() {
		super();

		const results = {};
		const checkData = [];
		this.assignments = tutorialObj[strings.getLanguage()];
		this.falseAnswersCheck = allAnswers;
		this.test = testObj[strings.getLanguage()];
		for (const assignment of this.assignments) {
			results[assignment.id] = '';
		}
		for (let i = 0; i < this.falseAnswersCheck.length; i++) {
			checkData.push({
				id: i + 1,
				isTaken: this.falseAnswersCheck[i].isTaken,
				value: this.falseAnswersCheck[i].value,
				isCorrect: this.falseAnswersCheck[i].isCorrect,
			});
		}

		this.state = {
			results,
			checkData,
			timeRanOut: false,
			counter: 1,
			limit: 6,
			hideComment: false,
			showTestPanel: true,
			scrollPosition: 0,
			timeOut: false,
			nextQuestion: false,
			bruteFinish: false,
			showChoicImages:false,

		};

		this.audioRefs = {};

		this.singleAssignment = {
			1: this.test.assignment1,
			2: this.test.assignment2,
			3: this.test.assignment3,
			4: this.test.assignment4,
			5: this.test.assignment5,
			6: this.test.assignment6,
			7: this.test.assignment7,
			8: this.test.assignment8,
			9: this.test.assignment9,
			10: this.test.assignment10,
			11: this.test.assignment11,
			12: this.test.assignment12,
			13: this.test.assignment13,
			14: this.test.assignment14,
			15: this.test.assignment15,
			16: this.test.assignment16,

			17: this.test.assignment17,
			18: this.test.assignment18,
			19: this.test.assignment19,
			20: this.test.assignment20,
			21: this.test.assignment21,
			22: this.test.assignment22,
		};

		this.audioRefs = {};
	}

	setResults(id, value) {
		const {results, checkData} = this.state;

		count = count + 1;

		for (let i = 0; i < checkData.length; i++) {
			if (checkData[i].id === this.state.counter) {
				checkData[i].isTaken = true;
				if (checkData[i].value === value) {
					checkData[i].isCorrect = true;
				}
			}
		}
		this.updateCount('setResults');

		this.toggleTimeout('userAction');
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
			scrollPosition: window.pageYOffset,
			setTimeout: false,
			checkData,
			showChoicImages:false,
		});
		/* this.checkForConsecutiveMistake(); */
		this.scrollToTop();
	}
	updateCount(origin) {
		timeFlag = false;
		const {checkData} = this.state;
		if (origin === 'timer') {
			for (let i = 0; i < checkData.length; i++) {
				if (checkData[i].id === this.state.counter) {
					checkData[i].isTaken = true;
				}
			}
			this.setState({
				counter: count,
				hideComment: false,
				timeOut: false,
				showTestPanel: false,
				checkData,
				nextQuestion: true,
			});
			this.props.onShowAvatar(true);
		} else {
			this.setState({
				counter: count,
				hideComment: false,
				timeOut: false,
			});
			this.props.onShowAvatar(false);
		}
		/* this.checkForConsecutiveMistake(); */
	}

	scrollToTop = () => {
		window.scrollTo({
			top: 169,
			behavior: 'smooth',
		});
	};

	toggleTimeout(origin) {
		timeFlag = false;
		if (origin === 'audioAction') {
			this.setState({
				timeOut: true,
				showChoicImages:true,
			});
		}

		if (origin === 'userAction') {
			timeFlag = false;
			this.setState({
				timeOut: false,
					showChoicImages:true,
			});
		}
	}

	checkForConsecutiveMistake() {
		const { checkData} = this.state;

		const maxMistakeThreshold = 5;
		const iterator = [];
		let checkConsitencyFlag = 0;
		for (let i = 0; i < checkData.length; i++) {
			if (checkData[i].isTaken && !checkData[i].isCorrect) {
				iterator.push(i);
			}
		}
		if (iterator.length >= maxMistakeThreshold) {
			for (let i = 0; i < iterator.length; i++) {
				if (iterator[i + 1] !== 'undefined') {
					if (iterator[i + 1] - iterator[i] === 1) {
						checkConsitencyFlag = checkConsitencyFlag + 1;
						if (checkConsitencyFlag >= maxMistakeThreshold) {
							this.setState({
								bruteFinish: true,
							});
							break;
						}
					} else {
						checkConsitencyFlag = 0;
					}
				}
			}
		}
	}

	render() {
		const {onNextStep} = this.props;

		const {results, timeRanOut, bruteFinish} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		/* const isNextButtonVisible = allResultsHasValues || timeRanOut; */
		const isNextButtonVisible = count === 22 || timeRanOut || bruteFinish;

		let filteredAssignments;

		if (timeRanOut) {
			filteredAssignments = this.singleAssignment[count].filter(
				(assignment) => {
					return results[assignment.id];
				}
			);
		} else {
			filteredAssignments = this.assignments;
		}

		return (
			<div className="lit-test">
				{this.state.timeOut && (
					<div
						className="lit-infobar"
						key={this.singleAssignment[this.state.counter]}
					>
						<Countdown
							duration={10}
							onComplete={() => {
								count = count + 1;
								this.updateCount('timer');
							}}
						/>
					</div>
				)}

				<div className="lit-tulleord">
				<div className="lit-tulleord lit-tulleord__tutorial">
					{this.singleAssignment[this.state.counter].map(
						(assignment) => {
							const {id, choices} = assignment;

							const currentAssignmentValue = results[id];
							let audioPlayerRef;
							for (let choice of choices) {
								if (id === 22) break;
								if (
									currentAssignmentValue === choice.word &&
									!this.state.hideComment
								) {
								}
							}
							if (
								this.state.showTestPanel &&
								count < 22 &&
								!this.state.bruteFinish
							) {
								return (
									<div className="lit-words-wrapper" key={id}>
										<div className="lit-single-word">
											<p>
												<p className="instruction">
													<AudioPlayer
														inline
														id={
															this
																.singleAssignment[
																count
															].id
														}
														key={
															this
																.singleAssignment[
																count
															].id
														}
														autoplay
														onComplete={() => {
															this.toggleTimeout(
																'audioAction'
															);
														}}
														 onPause={() => {
															this.toggleTimeout(
																'audioAction'
															);
														}}  
														ref={(ref) => {
															audioPlayerRef = ref;
														}}
														refs={this.audioRefs}
														src={
															assignment.mainSound
														}
													/>
													{assignment.text}
												</p>
											</p>
										</div>
										<div className="lit-main-img-container">
											<img
												className={'imgMainItem'}
												src={assignment.mainImg}
											/>
										</div>

										{this.state.showChoicImages && (
											<div className="lit-words">
											<span className="lit-input__label lit-input__label--count">
												{id}
											</span>

											<div className="lit-word-list">
												{choices.map((choice, i) => {
													const idWord =
														i + choice.word;
													return (
														<span
															className="lit-word"
															key={idWord}
														>
															<input
																checked={
																	currentAssignmentValue ===
																	choice.word
																}
																className="lit-word__field"
																id={idWord}
																onChange={() =>
																	this.setResults(
																		id,
																		choice.word
																	)
																}
																type="radio"
															/>
															<label
																className="lit-word__label"
																htmlFor={idWord}
															>
																<div className="checkbox" />
																{/* {choice.word} */}
																<span>
																	<img
																		className={
																			'imgItem'
																		}
																		src={
																			choice.img
																		}
																	 />
																</span>
															</label>
														</span>
													);
												})}
											</div>
										</div>

										)}
										{/* {comment} */}
									</div>
								);
							}
						}
					)}
				</div>
				</div>

				{isNextButtonVisible && (
					<>
					<p>
					<AudioPlayer
						
						autoplay
						inline
						src={finalFinishSound}
					/>
					{strings.tulleordTestFinished}
				</p>
				<br/>
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
					>
						{strings.done}
					</button>
					</>
				)}
				{this.state.nextQuestion &&
					count < 22 &&
					!this.state.bruteFinish && (
						<button
							className="lit-btn bg-primary"
							onClick={() => {
								this.setState({
									showTestPanel: true,
									nextQuestion: false,
									showChoicImages:false,
								});
								this.props.onShowAvatar(false);
							}}
						>
							{'Neste'}
						</button>
					)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
