import audio1 from '../../../../assets/sounds/child/de/diktat/trial1.mp3';
import audio2 from '../../../../assets/sounds/child/de/diktat/trial2.mp3';
import audio3 from '../../../../assets/sounds/child/de/diktat/trial3.mp3';

const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
};
export default audioFiles;
