import React from 'react';
import {connect} from 'react-redux';

import AudioPlayer from '../../components/AudioPlayer';

import strings from '../../utils/strings';

import tutorialVideo from './assets/e845140cdea968bfb0a8144fb06a7064.mp4';

import previewVideoNo from './assets/e845140cdea968bfb0a8144fb06a7064.mp4';
import previewVideoNn from './assets/e845140cdea968bfb0a8144fb06a7064.mp4';

import previewVideoSe from './assets/e845140cdea968bfb0a8144fb06a7064.mp4';
import previewVideoDe from './assets/DE_Ordforståelse.mp4';

import {VARIATION} from '../../constants';

class Intro extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};
		this.audio = require(`../../assets/sounds/${variation}/${language}/6 1 Ordforstaelse Intro.mp3`);
		this.video = previewVideoObj[language];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div>
				<div className="text-left">
					<p>
						<AudioPlayer
							autoplay
							inline
							onPause={() => {
								videoRef.play();
							}}
							src={this.audio}
						/>
						{strings.ordforstaelseIntro}
					</p>
					<br/>
					<p>{strings.ordforstaelseTimelimit}</p>
				</div>
				<div className="lit-preview">
					<p className="lit-preview__label">
						{strings.testLooksLikeThis}
					</p>
					<video
						className="lit-preview__video"
						loop
						ref={(ref) => {
							videoRef = ref;
						}}
						src={this.video}
					/>
				</div>
					<p>{strings.clickStartTest}</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
