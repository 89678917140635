import React from 'react';
import voice1No from '../../../assets/sounds/child/de/bytt-no-1.mp3';
import voice2No from '../../../assets/sounds/child/de/bytt-no-2.mp3';
import voice3No from '../../../assets/sounds/child/de/bytt-no-3.mp3';
import voice4No from '../../../assets/sounds/child/de/bytt-no-4.mp3';
import voice5No from '../../../assets/sounds/child/de/bytt-no-5.mp3';
import voice6No from '../../../assets/sounds/child/de/bytt-no-6.mp3';
import voice7No from '../../../assets/sounds/child/de/bytt-no-7.mp3';
import voice8No from '../../../assets/sounds/child/de/bytt-no-8.mp3';
import voice9No from '../../../assets/sounds/child/de/bytt-no-9.mp3';
import voice10No from '../../../assets/sounds/child/de/bytt-no-10.mp3';
import voice11No from '../../../assets/sounds/child/de/bytt-no-11.mp3';
import voice12No from '../../../assets/sounds/child/de/bytt-no-12.mp3';
import voice13No from '../../../assets/sounds/child/de/bytt-no-13.mp3';
import voice14No from '../../../assets/sounds/child/de/bytt-no-14.mp3';
import voice15No from '../../../assets/sounds/child/de/bytt-no-15.mp3';
import voice16No from '../../../assets/sounds/child/de/bytt-no-16.mp3';
import voice17No from '../../../assets/sounds/child/de/bytt-no-17.mp3';
import voice18No from '../../../assets/sounds/child/de/bytt-no-18.mp3';
import voice19No from '../../../assets/sounds/child/de/bytt-no-19.mp3';
import voice20No from '../../../assets/sounds/child/de/bytt-no-20.mp3';
import voice21No from '../../../assets/sounds/child/de/bytt-no-21.mp3';

import car from '../assets/Bil.PNG';

import door from '../assets/Dør.PNG';
import sock from '../assets/Sokk.PNG';
import mouse from '../assets/Mus.PNG';

import teapot from '../assets/teapot.png';
import cup from '../assets/cup.png';
import christmasTree from '../assets/tree.png';
import bathtub from '../assets/bathtub.png';
import shark from '../assets/shark.png';
import bug from '../assets/bug.png';
import nose from '../assets/nose.png';
import vase from '../assets/vase.png';
import eye from '../assets/eye.png';
import rabbit from '../assets/rabbit.png';
import horse from '../assets/Hest.PNG';
import lungs from '../assets/lungs.png';
import tongue from '../assets/tongue.png';
import boy from '../assets/boy.png';
import lips from '../assets/lips.png';
import fox from '../assets/fox.png';
import whale from '../assets/whale.png';
import hall from '../assets/hall.png';
import valley from '../assets/valley.png';
import cow from '../assets/cow.png';
import wind from '../assets/wind.png';
import child from '../assets/child.png';
import salmon from '../assets/salmon.png';
import badger from '../assets/badger.png';
import wax from '../assets/wax.png';
import steel from '../assets/steel.png';
import chair from '../assets/chair.png';
import broom from '../assets/broomhandle.png';
import money from '../assets/money.png';
import table from '../assets/table.png';
import animal from '../assets/animal.png';
import doorhandle from '../assets/doorhandle.png';
import tube from '../assets/tube.png';
import gate from '../assets/gate.png';
import hammer from '../assets/hammer.png';
import checkstand from '../assets/checkstand.png';
import cat from '../assets/cat.png';
import gouge from '../assets/gouge.png';
import rib from '../assets/rib.png';
import gutter from '../assets/gutter.png';
import tooth from '../assets/tooth.png';
import ten from '../assets/ten.png';
import rooster from '../assets/rooster.png';
import beasts from '../assets/beasts.png';
import forest from '../assets/forest.png';
import earth from '../assets/earth.png';
import bride from '../assets/bride.png';
import groom from '../assets/groom.png';
import foam from '../assets/foam.png';
import brown from '../assets/brown.png';
import thorn from '../assets/thorn.png';
import village from '../assets/village.png';
import rose from '../assets/rose.png';
import grain from '../assets/grain.png';
import leg from '../assets/leg.png';
import foot from '../assets/foot.png';
import axe from '../assets/axe.png';
import leaf from '../assets/leaf.png';
import pig from '../assets/pig.png';
import cap from '../assets/cap.png';
import animaljaw from '../assets/animaljaw.png';
import rat from '../assets/rat.png';
import belly from '../assets/belly.png';
import tallTree from '../assets/tallTree.png';
import hat from '../assets/hat.png';
import blood from '../assets/blood.png';
import chicken from '../assets/chicken.png';
import kiss from '../assets/kiss.png';
import puzzle from '../assets/puzzle.png';
import bush from '../assets/bush.png';
import purse from '../assets/Veske.PNG';
import needle from '../assets/Sy.PNG';
import bus from '../assets/Buss.PNG';
import house from '../assets/Hus.PNG';
import lice from '../assets/Lus.PNG';
import bag from '../assets/Sekk.PNG';


import starterTesten from '../../../assets/sounds/child/de/3 13 byttelyd Starter testen 2.mp3';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'lys',
		},
		{
			word: 'lese',
		},
		{
			word: 'katt',
		},
		{
			isCorrect: true,
			word: 'kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: teapot,
		text: <span>Welches neue Wort kommt dabei heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/k/</span> in <span style={{color:'cyan'}}>Kanne</span> durch den Laut <span style={{color:'cyan'}}>/t/</span> ersetzt? Klicke auf das passende Bild!</span>,
		mainSound: voice1No,
		choices: [
			{
				word: 'tasse',

				img: cup,
			},
			{
				word: 'tanne',

				img: christmasTree,
			},
			{
				word: 'wanne',

				img: bathtub,
			},
			{
				word: 'hai',

				img: shark,
			},
		],
	},
];
export const assignment2 = [
	{
		id: 2,
		mainImg: lice,
		text: <span>Welches neue Wort kommt heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/l/</span> in <span style={{color:'cyan'}}>Laus</span> durch den Laut <span style={{color:'cyan'}}>/m/</span> ersetzt? Klicke auf das passende Bild!</span>,
		mainSound: voice2No,
		choices: [
			{
				word: 'haus',

				img: house,
			},
			{
				word: 'käfer',

				img: bug,
			},
			{
				word: 'socke',

				img: sock,
			},
			{
				word: 'maus',

				img: mouse,
			},
			
		],
	},
];
export const assignment3 = [
	{
		id: 3,
		mainImg: nose,
		text: <span>Welches neue Wort kommt heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/n/</span> in <span style={{color:'cyan'}}>Nase</span> durch den Laut <span style={{color:'cyan'}}>/h/</span> ersetzt? Klicke auf das passende Bild!</span>,
		mainSound: voice3No,
		choices: [
			{
				word: 'vase',

				img: vase,
			},
			{
				word: 'auge',

				img: eye,
			},
			{
				word: 'hase',

				img: rabbit,
			},
			{
				word: 'pferd',

				img: horse,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: lungs,
		text: <span>Welches neue Wort kommt heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/l/</span> in <span style={{color:'cyan'}}>Lunge</span> durch den Laut <span style={{color:'cyan'}}>/z/</span> ersetzt? Klicke auf das passende Bild!</span>,
		mainSound: voice4No,
		choices: [
			{
				word: 'zunge',

				img: tongue,
			},
			{
				word: 'junge',

				img: boy,
			},
			{
				word: 'lippe',

				img: lips,
			},
			{
				word: 'fuchs',

				img: fox,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 5,
		mainImg: whale,
		text: <span>Welches neue Wort kommt heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/w/</span> in <span style={{color:'cyan'}}>Wal</span> durch den Laut <span style={{color:'cyan'}}>/s/</span> ersetzt? Klicke auf das passende Bild!</span>,
		mainSound: voice5No,
		choices: [
			{
				word: 'hai',

				img: shark,
			},
			{
				word: 'tasse',

				img: cup,
			},
			{
				word: 'tal',

				img: valley,
			},
			{
				word: 'saal',

				img: hall,
			},
		
		],
	},
];
export const assignment6 = [
	{
		id: 6,
		mainImg: cow,
		text: <span>Welches neue Wort kommt heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/r/</span> in <span style={{color:'cyan'}}>Rind</span> durch den Laut <span style={{color:'cyan'}}>/k/</span> ersetzt? Klicke auf das passende Bild!</span>,
		mainSound: voice6No,
		choices: [
			{
				word: 'wind',

				img: wind,
			},
			{
				word: 'pferd',

				img: horse,
			},
			{
				word: 'kind',

				img: child,
			},
			{
				word: 'lippe',

				img: lips,
			},
		],
	},
];
export const assignment7 = [
	{
		id: 7,
		mainImg: salmon,
		text: <span>Welches neue Wort kommt heraus, wenn Du den ersten Laut <span style={{color:'cyan'}}>/l/</span> in <span style={{color:'cyan'}}>Lachs</span> durch den Laut <span style={{color:'cyan'}}>/d/</span> ersetzt?</span>,
		mainSound: voice7No,
		choices: [
			{
				word: 'dachs',

				img: badger,
			},
			{
				word: 'auge',

				img: eye,
			},
			{
				word: 'wachs',

				img: wax,
			},
			{
				word: 'fuchs',

				img: fox,
			},
		],
	},
];
export const assignment8 = [
	{
		id: 8,
		mainImg: steel,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/a/</span> in <span style={{color:'cyan'}}>Stahl</span> durch den Laut <span style={{color:'cyan'}}>/u/</span> ersetzt?</span>,
		mainSound: voice8No,
		choices: [
			{
				word: 'geld',

				img: money,
			},
			{
				word: 'stuhl',

				img: chair,
			},
			{
				word: 'stiel',

				img: broom,
			},
			{
				word: 'tisch',

				img: table,
			},
		],
	},
];
export const assignment9 = [
	{
		id: 9,
		mainImg: animal,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/i/</span> in <span style={{color:'cyan'}}>Tier</span> durch den Laut <span style={{color:'cyan'}}>/ü/</span> ersetzt?</span>,
		mainSound: voice9No,
		choices: [
			{
				word: 'tür',

				img: door,
			},
			{
				word: 'klinke',

				img: doorhandle,
			},
			{
				word: 'rohr',

				img: tube,
			},
			{
				word: 'tor',

				img: gate,
			},
		],
	},
];
export const assignment10 = [
	{
		id: 10,
		mainImg: cup,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/s/</span> in <span style={{color:'cyan'}}>Tasse</span> durch den Laut <span style={{color:'cyan'}}>/sch/</span> ersetzt?</span>,
		mainSound: voice10No,
		choices: [
			{
				word: 'becher',

				img: purse,
			},
			{
				word: 'hammer',

				img: hammer,
			},
			{
				word: 'tanne',

				img: christmasTree,
			},
			{
				word: 'tasche',

				img: bag,
			},
		],
	},
];
export const assignment11 = [
	{
		id: 11,
		mainImg: checkstand,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/l/</span> in <span style={{color:'cyan'}}>Rille</span> durch den Laut <span style={{color:'cyan'}}>/n/</span> ersetzt?</span>,
		mainSound: voice11No,
		choices: [
			{
				word: 'kanne',

				img: teapot,
			},
			{
				word: 'katze',

				img: cat,
			},
			{
				word: 'tasche',

				img: bag,
			},
			{
				word: 'geld',

				img: money,
			},
		],
	},
];
export const assignment12 = [
	{
		id: 12,
		mainImg: gouge,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/s/</span> in <span style={{color:'cyan'}}>Kasse</span> durch den Laut <span style={{color:'cyan'}}>/tz/</span> ersetzt?</span>,
		mainSound: voice12No,
		choices: [
			{
				word: 'rippe',

				img: rib,
			},
			{
				word: 'rohr',

				img: tube,
			},
			{
				word: 'rinne',

				img: gutter,
			},
			{
				word: 'klinke',

				img: doorhandle,
			},
		],
	},
];
export const assignment13 = [
	{
		id: 13,
		mainImg: tooth,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/a/</span> in <span style={{color:'cyan'}}>Zahn</span> durch den Laut <span style={{color:'cyan'}}>/e/</span> ersetzt?</span>,
		mainSound: voice13No,
		choices: [
			{
				word: 'lippen',

				img: lips,
			},
			{
				word: 'zehn',

				img: ten,
			},
			{
				word: 'hahn',

				img: rooster,
			},
			{
				word: 'auto',

				img: car,
			},
		],
	},
];
export const assignment14 = [
	{
		id: 14,
		mainImg: beasts,
		text: <span>Welches neue Wort kommt heraus, wenn Du den mittleren Laut <span style={{color:'cyan'}}>/i/</span> in <span style={{color:'cyan'}}>Wild</span> durch den Laut <span style={{color:'cyan'}}>/a/</span> ersetzt?</span>,
		mainSound: voice14No,
		choices: [
			{
				word: 'hase',

				img: rabbit,
			},
			{
				word: 'tisch',

				img: table,
			},
			{
				word: 'wald',

				img: forest,
			},
			{
				word: 'welt',

				img: earth,
			},
		],
	},
];
export const assignment15 = [
	{
		id: 15,
		mainImg: bride,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/t/</span> in <span style={{color:'cyan'}}>Braut</span> durch den Laut <span style={{color:'cyan'}}>/n/</span> ersetzt?</span>,
		mainSound: voice15No,
		choices: [
			{
				word: 'bräutigam',

				img: groom,
			},
			{
				word: 'nadel',

				img: needle,
			},
			{
				word: 'schaum',

				img: foam,
			},
			{
				word: 'braun',

				img: brown,
			},
		],
	},
];
export const assignment16 = [
	{
		id: 16,
		mainImg: thorn,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/n/</span> in <span style={{color:'cyan'}}>Dorn</span> durch den Laut <span style={{color:'cyan'}}>/f/</span> ersetzt?</span>,
		mainSound: voice16No,
		choices: [
			{
				word: 'dorf',

				img: village,
			},
			{
				word: 'rose',

				img: rose,
			},
			{
				word: 'stuhl',

				img: chair,
			},
			{
				word: 'korn',

				img: grain,
			},
		],
	},
];
export const assignment17 = [
	{
		id: 17,
		mainImg: leg,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/n/</span> in <span style={{color:'cyan'}}>Bein</span> durch den Laut <span style={{color:'cyan'}}>/l/</span> ersetzt?</span>,
		mainSound: voice17No,
		choices: [
			{
				word: 'fuß',

				img: foot,
			},
			{
				word: 'beil',

				img: axe,
			},
			{
				word: 'blatt',

				img: leaf,
			},
			{
				word: 'schwein',

				img: pig,
			},
		],
	},
];
export const assignment18 = [
	{
		id: 18,
		mainImg: mouse,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/s/</span> in <span style={{color:'cyan'}}>Maus</span> durch den Laut <span style={{color:'cyan'}}>/l/</span> ersetzt?</span>,
		mainSound: voice18No,
		choices: [
			{
				word: 'kappe',

				img: cap,
			},
			{
				word: 'maul',

				img: animaljaw,
			},
			{
				word: 'haus',

				img: house,
			},
			{
				word: 'ratte',

				img: rat,
			},
		],
	},
];
export const assignment19 = [
	{
		id: 19,
		mainImg: tallTree,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/m/</span> in <span style={{color:'cyan'}}>Baum</span> durch den Laut <span style={{color:'cyan'}}>/x(ch)/</span> ersetzt?</span>,
		mainSound: voice19No,
		choices: [
			{
				word: 'bauch',

				img: belly,
			},
			{
				word: 'schaum',

				img: foam,
			},
			{
				word: 'ratte',

				img: rat,
			},
			{
				word: 'blatt',

				img: leaf,
			},
		],
	},
];
export const assignment20 = [
	{
		id: 20,
		mainImg: hat,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/t/</span> in <span style={{color:'cyan'}}>Hut</span> durch den Laut <span style={{color:'cyan'}}>/n/</span> ersetzt?</span>,
		mainSound: voice20No,
		choices: [
			{
				word: 'blut',

				img: blood,
			},
			{
				word: 'fuß',

				img: foot,
			},
			{
				word: 'huhn',

				img: chicken,
			},
			{
				word: 'kappe',

				img: cap,
			},
		],
	},
];
export const assignment21 = [
	{
		id: 21,
		mainImg: bus,
		text: <span>Welches neue Wort kommt heraus, wenn Du den letzten Laut <span style={{color:'cyan'}}>/s/</span> in <span style={{color:'cyan'}}>Bus</span> durch den Laut <span style={{color:'cyan'}}>/sch/</span> ersetzt?</span>,
		mainSound: voice21No,
		choices: [
			{
				word: 'auto',

				img: car,
			},
			{
				word: 'kuss',

				img: kiss,
			},
			{
				word: 'puzzle',

				img: puzzle,
			},
			{
				word: 'busch',
				
				img: bush,
			},
		],
	},
];
export const assignment22 = [
	{
		id: 22,
		mainImg: bush,
		text: '',
		mainSound: voice21No,
		choices: [
			{
				word: 'modil',

				img: bush,
			},
			{
				word: 'vuks',

				img: bush,
			},
			{
				word: 'fålk',

				img: bush,
			},
			{
				word: 'flynt',

				img: bush,
			},
		],
	},
];
